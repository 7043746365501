#button-container button {
  padding: 7px;
  border-radius: 5px;
  margin: 5px;
  box-shadow: 1px 1px 1px #b7b7b7;
  visibility: visible;
  background-color: #0088ff;
  font-size: 13px;
  color: #ffffff;
}

#button-container button:hover, #button-container button:disabled {
  background-color: #0078e0;
}

#button-container button:active, #button-container button:disabled {
  box-shadow: none !important;
}

#clear {
  background-color: #fd5858 !important;
  color: #000000 !important;
}

#clear:hover {
  background-color: #ea4f4f !important;
}
