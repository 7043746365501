.modal {
  position: fixed;
  height: 200px;
  width: 80%;
  max-width: 500px;
  border-radius: 3px;
  margin-top: 50px;
  box-shadow: 0px 0px 3px #a2a2a2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #ffffff;
  text-align: left;
  z-index: 100;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 15px;
}

.modal-header {
  border-bottom: 1px #dcdcdc solid;
  padding-bottom: 10px;
  margin: 15px;
  font-size: 20px;
}

.success {
  color: #0adc00;
}

.fail {
  color: #ff0000;
}

.modal-body {
  height: 100px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  text-align: center;
}

.link-container {
  margin-top: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.link-container a {
  margin-right: 7px;
  color: #0edc00;
}

.link-container a:hover {
  font-weight: bold;
}

.link-container button {
  font-size: 13px;
}

.link-container button:hover {
  text-shadow: 0.2px 0.2px 0px #000000;
}
