.chart-container {
  margin-top: 10px;
}

.chart-button-container {
  padding: 5px;
  margin-bottom: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.chart-button {
  height: 20px;
  width: 20px;
  margin: 2px;
  visibility: visible;
  background: none;
  color: #dcdcdc;
}

.chart-button:hover {
  color: #989696;
}

.pie {
  margin-right: 6px;
}

.radar, .bar {
  margin-right: 4px;
}

.selected {
  outline: none;
  color: #989696 !important;
  text-shadow: 0.3px 0.3px 0px #444444;
}

.chart-button i {
  font-size: 20px;
}

.pie i {
  font-size: 18px;
}

.chart-visual-container {
  position: relative;
  height: 300px;
  width: 300px;
}

.chart-visual-container.week, .chart-visual-container.month {
  height: 250px;
  width: 250px;
}

@media only screen and (min-width: 630px) {
  .chart-button-container.week, .chart-button-container.month {
    margin-bottom: 0px;
  }

  .chart-container {
    margin-top: 0px;
  }

  .pie.week, .pie.month {
    margin-right: 4px;
  }

  .radar.week, .radar.month, .bar.week, .bar.month {
    margin-right: 2px;
  }

  .chart-button {
    visibility: hidden;
  }

  .chart-button.week, .chart-button.month {
    height: 18px;
    width: 18px;
  }

  .chart-container:hover .chart-button {
    visibility: visible !important;
  }

  .chart-button.week i, .chart-button.month i {
    font-size: 18px;
  }

  .pie.week i, .pie.month i {
    font-size: 16px;
  }

  .chart-visual-container.week, .chart-visual-container.month {
    height: 200px;
    width: 200px;
  }
}
