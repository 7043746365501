input {
  padding: 3px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  text-align: center;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus::-webkit-outer-spin-button {
  -webkit-appearance: outer-spin-button;
}

input:focus::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
}

.new {
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
}

@media only screen and (min-width: 630px) {
  input.week, input.month {
    font-size: 11px;
  }
}
