table {
  border-collapse: collapse;
  margin-bottom: 20px;
  text-align: center;
}

th {
  border-bottom: 1px solid #dddddd;
  padding: 10px;
  font-size: 14px;
}

td {
  padding: 5px;
  font-size: 14px;
}

.activity {
  width: 164px;
}

.activity input {
  width: 154px;
}

.hours {
  width: 70px;
}

.hours input {
  width: 54px;
}

td button {
  width: 21px;
  background: none;
}

td button:hover {
  text-shadow: 0.2px 0.2px 0px #000000;
}

td button:active {
  text-shadow: 0.5px 0.5px 0px #000000;
}

.delete {
  visibility: hidden;
  font-size: 14px;
}

tr:hover td .delete {
  visibility: visible;
}

.error {
  margin-top: 5px;
  color: #ff0000;
  font-size: 10px;
}

@media only screen and (min-width: 630px) {
  table.day {
    margin-bottom: 0px;
    margin-right: 40px;
  }

  table.week, table.month {
    margin-bottom: 10px;
  }

  table.week th, table.month th, table.week td, table.month td {
    font-size: 11px;
  }

  table.week .activity, table.month .activity {
    width: 100px;
  }

  table.week .activity input, table.month .activity input {
    width: 95px;
  }

  table.week .hours, table.month .hours {
    width: 53px;
  }

  table.week .hours input, table.month .hours input {
    width: 45px;
  }
}
