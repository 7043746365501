body {
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
}

.hidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

#root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #0edc00;
}

*:focus {
  outline-color: #0edc00;
  outline-width: 2px;
}

::placeholder {
  font-style: italic;
}

button {
  padding: 0px;
  border: none;
  font-family: 'Raleway', sans-serif;
  cursor: pointer;
}

button:active, button:disabled {
  outline: none;
  text-shadow: 0.3px 0.3px 0px #000000;
}

button:disabled {
  cursor: not-allowed !important;
}

#footer {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Rajdhani', sans-serif;
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
  z-index: 100;
}
