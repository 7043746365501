.day-container, .data-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.day-container.week, .day-container.month {
  padding: 15px;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  margin-top: -1px;
  margin-left: -1px;
}

.day-header {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

@media only screen and (min-width: 630px) {
  .data-container.day {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .day-header {
    font-size: 16px;
  }
}
