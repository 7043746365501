#main-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#app-container, #button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

#title {
  margin-bottom: 20px;
  font-family: 'Satisfy', cursive;
  font-size: 54px;
}

#subtitle {
  margin-bottom: 25px;
  font-weight: normal;
}

select {
  padding: 3px;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  background: none;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

#month-toggle {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#month-toggle button {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
}

#month-toggle h2 {
  width: 160px;
  margin: 0px;
  font-size: 20px;
}

#day-data-container {
  padding: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#button-container {
  margin-top: 45px;
}

#save-container {
  margin-top: 12px;
}

#save {
  width: 60px;
  height: 36px;
  padding: 7px;
  border-radius: 5px;
  margin: 5px;
  box-shadow: 1px 1px 1px #b7b7b7;
  visibility: visible;
  background-color: #0edc00;
  font-size: 17px;
  color: #ffffff;
}

#save:hover, #save:disabled {
  background-color: #0db902;
}

#save:active, #save:disabled {
  box-shadow: none !important;
}

@media only screen and (min-width: 630px) {
  #subtitle {
    margin-bottom: 45px;
  }

  #day-data-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  #button-container {
    margin-top: 60px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
