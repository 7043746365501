#calculator-container {
  padding: 10px;
  margin-top: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

h3 {
  margin-bottom: 5px;
  font-weight: normal;
}

#age-start, #age-end {
  width: 40px;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  text-align: center;
}

#calculator {
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  line-height: 1.5;
}

#calculator th {
  font-size: 13px;
}

#calculator td {
  font-size: 12px;
}
